<template>
  <div class="fp-container">
    <div class="fp-head">
      <login-form-head></login-form-head>
    </div>
    <div class="fp-main">
      <forget-password-form></forget-password-form>
    </div>
    <floot-nav :navColor="$route.meta.navColor"></floot-nav>
  </div>
</template>

<script>
import ForgetPasswordForm from '@/components/forget-password-form/forget-password-form'
import LoginFormHead from '@/components/login-form-head/login-form-head'
import flootNav from '@/components/flootNav/flootNav.vue'
export default {
  name: 'forget-password',
  components: { LoginFormHead, flootNav, ForgetPasswordForm }
}
</script>

<style scoped>
.fp-container {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
}

.fp-main {
  width: 100%;
  height: 100%;

  margin: 50px 0;
  display: flex;
  justify-content: center;
}
</style>
