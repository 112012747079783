<template>
  <div>
    <el-row>
      <el-col :class="isColor ? 'line-color': 'white-color'" :span="24">
        <div class="container">
          <el-row>
            <el-col class="lh-content" :span="24">
              <el-image fit="contain" :src="isColor ? imgScoure.deep : imgScoure.white"></el-image>
              <div class="dis-sta-cen">
                <div class="lh-panel">
                  <span class="lh-tel">{{ myCustomerServiceTel }}</span>
                  <span class="lh-time">客服时间：8:30-17:30</span>
                </div>
                <b></b>
                <el-link  type="primary" class="lh-return" :underline="false" href="/index">返回首页</el-link>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'login-form-head',
  data () {
    return {
      isColor: this.navColor,
      imgScoure: {
        white: require('@/assets/index/logo-w.png'),
        deep: require('@/assets/index/logo-s.png'),
        flooter: require('@/assets/index/logo-d.png')
      },
      myCustomerServiceTel: ''
    }
  },
  computed: {
    ...mapGetters([
      'customerServiceTel'
    ]),
    customerServiceTelChange: function () {
      const vm = this
      // console.log('计算属性: ' + JSON.parse(vm.vipData).vipLevel)
      if (vm.customerServiceTel) {
        return vm.customerServiceTel
      } else {
        return null
      }
    }
  },
  watch: {
    customerServiceTelChange: {
      immediate: true,
      handler: function (newValue, oldValue) {
        this.myCustomerServiceTel = newValue
      }
    }
  }
}
</script>

<style scoped>
.container {
  margin-top: 40px;
}
.lh-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lh-content b {
  background: #CCCCCC;
  display: inline-block;
  width: 1px;
  height: 50px;
  vertical-align: middle;
  margin: 0 20px;
}

.lh-panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /*justify-content: space-between;*/
}

.lh-tel {
  font-size: 24px;
  color: #333333;
}

.lh-time {
  font-size: 12px;
  color: #666666;
}

.lh-return {
  font-size: 14px;
  color: #5D6FE9;
}
</style>
